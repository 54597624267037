import { render, staticRenderFns } from "./function.vue?vue&type=template&id=26306d4b&scoped=true&"
import script from "./function.vue?vue&type=script&lang=js&"
export * from "./function.vue?vue&type=script&lang=js&"
import style0 from "./function.vue?vue&type=style&index=0&id=26306d4b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26306d4b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tgs-automation/tgs-automation/mojaweb/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26306d4b')) {
      api.createRecord('26306d4b', component.options)
    } else {
      api.reload('26306d4b', component.options)
    }
    module.hot.accept("./function.vue?vue&type=template&id=26306d4b&scoped=true&", function () {
      api.rerender('26306d4b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/department/function.vue"
export default component.exports