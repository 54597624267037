<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}

.btn {
	margin-bottom: 8px;
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	padding: 1.25rem;
	flex: 1 1 auto;
}
</style>
<template>
	<errorContainer :error="erreur" :warning="warning">
		<div class="container-layout px-0 animated fadeIn">
			<b-row v-if="Funcs">
				<b-col cols="12" xl="12">
					<div class="card card-border-blue-light">
						<h4>{{ this.FormMSG(1, 'Functions for department:') }} &laquo; {{ this.departmentName }} &raquo;</h4>
						<b-row class="form pt-2">
							<span id="top-function"></span>
							<b-col sm="8">
								<b-form-group :label="FormMSG(200, 'Prefix:')" label-for="prefix" :label-cols="3">
									<b-form-input v-model="newFun.prefix" type="text" id="prefix"></b-form-input>
								</b-form-group>
								<b-form-group :label="FormMSG(2, 'Function:')" label-for="funName" :label-cols="3">
									<b-form-input v-model="newFun.message" type="text" id="funName"></b-form-input>
								</b-form-group>
								<b-form-group :label="FormMSG(24, 'Short code')" label-for="shortCode" :label-cols="3">
									<b-form-input id="shortCode" v-model="newFun.shortCode" type="text" />
								</b-form-group>
								<b-form-group :label="FormMSG(100, 'Category')" label-for="catName" :label-cols="3">
									<treeselect
										:flat="true"
										:disable-branch-nodes="false"
										v-model="newFun.categoryLinkedToFun"
										:multiple="false"
										:options="Cats"
									/>
								</b-form-group>
								<b-form-group :label="FormMSG(9, 'Reference')" label-for="depName" :label-cols="3">
									<b-form-input id="depName" v-model="newFun.costCenter" disabled type="text" />
								</b-form-group>
							</b-col>
							<b-col sm="4">
								<b-row>
									<b-col>
										<div
											class="w-100"
											:class="`${$screen.width <= 576 ? 'footer-fixed' : 'pull-right'}`"
											:style="`left:${$screen.width <= 576 ? '0' : '15'}px;`"
										>
											<b-row>
												<b-col md="12">
													<b-button @click="curFunUpd()" variant="primary" :disabled="this.disableSave" size="md" block>{{
														this.FormMSG(4, 'Save')
													}}</b-button>
												</b-col>
											</b-row>
											<b-row>
												<b-col md="12">
													<b-button @click="goBack" variant="primary" size="md" block>{{ this.FormMSG(3, 'Back') }}</b-button>
												</b-col>
											</b-row>
										</div>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
						<b-row>
							<b-col sm="4"> </b-col>
							<b-col sm="4">
								<b-button v-if="depValue != 0" @click="NewFunction" size="md" variant="primary" block>{{
									this.FormMSG(5, 'Create a new function')
								}}</b-button>
							</b-col>
							<b-col sm="4"> </b-col>
						</b-row>
						<b-modal
							header-class="header-class-modal-doc-package"
							:title="FormMSG(6, 'Success!')"
							class="modal-success"
							v-model="successModal"
							@ok="successModal = false"
							ok-variant="success"
							ok-only
						>
							{{ this.FormMSG(7, 'The new function name has been saved.') }}
						</b-modal>
						<b-modal
							header-class="header-class-modal-doc-package"
							:title="FormMSG(6, 'Success!')"
							class="modal-success"
							v-model="successModalDelete"
							@ok="successModalDelete = false"
							ok-variant="success"
							ok-only
						>
							{{ this.FormMSG(7, 'The function has been deleted.') }}
						</b-modal>
						<!--     TABLE OF Functions      use striped to highlight each even row   @row-selected="rowSelected" -->
						<b-row class="mt-2">
							<b-col cols="12" xl="12">
								<CardListBuilder v-if="$screen.width < 992" :items="Funcs" :fields="funMobileFields">
									<template slot="actions" slot-scope="data">
										<b-button variant="primary" @click="rowClicked(data.item)" size="sm">
											<i class="icon-eye"></i>
										</b-button>
										<b-button size="sm" variant="danger" @click="delItem(data.item.id)">
											<i class="icon-trash"></i>
										</b-button>
									</template>
								</CardListBuilder>
								<b-table
									v-if="$screen.width >= 992"
									:hover="hover"
									responsive="sm"
									ref="myTable"
									selectable
									:selectedVariant="selectedColor"
									:select-mode="selectMode"
									:items="Funcs"
									style="text-align: left"
									:fields="funfields"
									:current-page="currentPage"
									sticky-header="700px"
									:per-page="perPage"
									@row-clicked="rowClicked"
									:head-variant="hv"
									bordered
									small
								>
									<!-- <template slot="rem" slot-scope="data"> -->
									<template v-slot:cell(rem)="data">
										<component :is="getLucideIcon('Trash2')" color="#EA4E2C" :size="20" @click="delItem(data.item.id)" />
									</template>
								</b-table>
							</b-col>
						</b-row>
					</div>
				</b-col>
			</b-row>
		</div>
	</errorContainer>
</template>

<script>
// import gql from 'graphql-tag';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import { Trash2 as Trash } from 'lucide-vue';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { getFunctions, updateFunction, deleteFunction, addFunction } from '@/cruds/department.crud';
import { getBudgetHierarchicalCategoryJson } from '../../cruds/budget.crud';
import { removeAttributeTree } from '../../shared/utils';

export default {
	name: 'Function',
	mixins: [languageMessages, globalMixin, isSingleProjectMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		},
		depValue: {
			type: Number
		}
	},
	components: { Treeselect },
	data: () => {
		return {
			hv: 'dark',
			erreur: {},
			Funcs: [],
			departmentName: '',
			currentPage: 1,
			perPage: 0,
			warning: '',
			Cats: [],
			stopPropagation: '',
			selectMode: 'single',
			selectedColor: 'primary',
			successModal: false,
			successModalDelete: false,
			curFun: {},
			newFun: {}
		};
	},
	computed: {
		baseFields() {
			return [
				{
					key: 'costCenter',
					label: this.FormMSG(8, 'Reference'),
					sortable: true
				},
				{
					key: 'prefix',
					label: this.FormMSG(201, 'Prefix'),
					sortable: true
				},
				{
					key: 'message',
					label: this.FormMSG(21, 'Function name'),
					sortable: true
				},
				{
					key: 'shortCode',
					label: this.FormMSG(25, 'Short code'),
					sortable: true
				},
				{
					key: 'rem',
					label: this.FormMSG(22, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		funfields() {
			return this.baseFields;
		},
		funMobileFields() {
			const keys = ['rem'];
			return this.baseFields.filter((t) => !keys.includes(t.key));
		},
		disableSave() {
			if (
				this.curFun.message != this.newFun.message ||
				this.curFun.costCenter != this.newFun.costCenter ||
				this.curFun.shortCode != this.newFun.shortCode ||
				this.curFun.prefix != this.newFun.prefix ||
				this.curFun.categoryLinkedToFun != this.newFun.categoryLinkedToFun
			) {
				return false;
			} else {
				return true;
			}
		}
	},
	methods: {
		goBack() {
			// console.log("I wish to go back")
			this.$emit('goBack');
		},
		async curFunUpd() {
			// console.log("before call funId:", this.newFun);
			// loading variables from newFun
			const functionId = parseInt(this.newFun.id, 10);
			const functionName = this.newFun.message;
			const newCostCenter = this.newFun.costCenter;
			const shortCode = this.newFun.shortCode;
			const prefix = this.newFun.prefix;
			let category = -1;
			if (this.newFun.categoryLinkedToFun) {
				category = this.newFun.categoryLinkedToFun;
			}

			await updateFunction(functionId, functionName, newCostCenter, shortCode, prefix, category).then((result) => {
				if (result) {
					this.curFun = Object.assign({}, this.newFun);
					// find object in the list and update accordingly
					this.reloadData();
					let find = false;
					for (let i = 0; i < this.Funcs.length; i++) {
						if (this.Funcs[i].id === result.id) {
							this.Funcs[i].message = this.curFun.message;
							this.Funcs[i].costCenter = this.curFun.costCenter;
							this.Funcs[i].shortCode = this.curFun.shortCode;
							find = true;

							break;
						}
					}
					this.reloadData();

					if (find) {
						this.createToastForMobile(this.FormMSG(155, 'Success'), this.FormMSG(165, 'Function added successfully'), '');
					}
				}
			});
		},
		async reloadData() {
			// getting department from store
			const dep = store.getCurDepartment();
			this.departmentName = dep.message;
			const departmentNumber = +dep.value;
			this.Funcs = await getFunctions(departmentNumber);
			if (this.Funcs.length > 0) {
				this.curFun = this.Funcs[0];
				this.newFun = Object.assign({}, this.curFun);
				//   console.log("Functions:",this.Funcs);
			} else {
				this.curFun.departmentNumber = departmentNumber;
				//   console.log("in reload data this.curFun.departmentNumber:",this.curFun.departmentNumber);
			}
			const updateTree = (tree) => {
				return tree
					.map((node) => {
						const newNode = { ...node };

						if (newNode.data.parentDepartment > 0) {
							newNode.isDisabled = true;
						} else {
							newNode.isDisabled = '';
						}

						if (newNode.data.category !== 0) {
							if (newNode.children && newNode.children.length > 0) {
								newNode.children = updateTree(newNode.children); // Recursively update the children
							}
							return newNode;
						}
					})
					.filter((node) => node !== undefined);
			};
			await getBudgetHierarchicalCategoryJson(-2).then((res) => {
				let part = removeAttributeTree(res, 'children', null);
				let parts = updateTree(part);
				this.Cats = [
					{
						id: -1,
						label: this.FormMSG(76, 'Select a category')
					},
					...parts
				];
			});
		},
		delItem(id) {
			const action = async (deleteCategory) => {
				const functionId = parseInt(id, 10);
				await deleteFunction(functionId, deleteCategory).then(async (result) => {
					if (result) {
						await this.reloadData();
						this.createToastForMobile(this.FormMSG(155, 'Success'), this.FormMSG(158, 'Function deleted successfully'), '');
					}
				});
			};
			if (this.isFilmSingle) {
				let txt = this.FormMSG(1594, 'Are you sure you want to delete this function?');
				this.$bvModal
					.msgBoxConfirm(txt, {
						title: this.FormMSG(23, 'Confirm'),
						size: 'md',
						buttonSize: 'md',
						okVariant: 'primary',
						cancelVariant: 'secondary',
						okTitle: this.FormMSG(1596, 'Delete'),
						noCloseOnBackdrop: true,
						cancelTitle: this.FormMSG(1595, 'Cancel'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (v == null) return;
						if (!v) {
							return;
						} else {
							await action(true);
						}
					})
					.catch((err) => console.log(err));
			} else {
				let txt = this.FormMSG(
					1590,
					'Categories of budgets linked to this function exist. You can unlink the category and only delete the function, or delete both. What do you want to do?'
				);
				this.$bvModal
					.msgBoxConfirm(txt, {
						title: this.FormMSG(23, 'Confirm'),
						size: 'md',
						buttonSize: 'md',
						okVariant: 'primary',
						cancelVariant: 'secondary',
						okTitle: this.FormMSG(1591, 'Delete'),
						noCloseOnBackdrop: true,
						cancelTitle: this.FormMSG(1592, 'Delete function only'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (v == null) return;
						if (!v) {
							await action(false);
						} else {
							await action(true);
						}
					})
					.catch((err) => console.log(err));
			}
		},
		getRowCount(items) {
			//console.log(items)
			return items.length;
		},
		rowClicked(item) {
			this.curFun = item;
			this.newFun = Object.assign({}, item);
			this.$nextTick(() => {
				let element = document.getElementById('top-function');
				element.scrollIntoView({ behavior: 'smooth', block: 'end' });
			});
		},
		async NewFunction() {
			//   console.log("in department number:",this.curFun);
			const departmentId = parseInt(this.curFun.departmentNumber, 10);
			await addFunction(departmentId).then(async (result) => {
				if (result) {
					await this.reloadData();
					this.createToastForMobile(this.FormMSG(155, 'Success'), this.FormMSG(154, 'Function addedd successfully'), '');
				}
			});
		}
	},
	async created() {
		await this.reloadData();
	}
};
</script>
